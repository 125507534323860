.checkBoxLabel {
    margin-right: 3px;
}

.checkBox {
    margin-top: 19px;
    position: absolute;
    width: 2rem;
}

@media print {
    .hide-for-print {
        display: none;
    }
}

@media screen {
    .hide-for-screen {
        display: none;
    }
}