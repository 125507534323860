// Existing variables and their defaults
$nice-dates-color-gray-dark: #333;
$nice-dates-color-gray: $nice-dates-color-gray-dark; // old #999
$nice-dates-color-gray-light: #ddd;
$nice-dates-color-highlight: #d1edf6;
$nice-dates-color-accent: #003968;
$nice-dates-font-size-small: 1.2rem;
$nice-dates-font-size-base: 1.4rem;
$nice-dates-font-size-big: 1.6rem;

@import 'react-nice-dates/src/style.scss';

.nice-dates-day.-highlight:before {
  background-color: $nice-dates-color-highlight;
  opacity: 1;
}
